// React
import { Suspense, lazy } from "react";

// React-Router-DOM
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

// Component
const Home = lazy(() => import("./Home/Home"));

const Loading = () => <div>Loading...</div>;

function Routes() {
  return useRoutes([
    { path: "/", element: <Home /> },
    // {
    //   path: "/:teamId",
    //   element: <TeamPage />,
    // },
  ]);
}

export default function App() {
  return (
    <Router>
      <div>
        <Suspense fallback={<Loading />}>
          <Routes />
        </Suspense>
      </div>
    </Router>
  );
}
